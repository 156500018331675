<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Status</label>
            <b-select
              v-model="filter.status"
              :options="options.filter_statuses"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="without_mobile_number"
            v-model="filter.without_mobile_number"
            name="without_mobile_number"
            value="1"
            unchecked-value="0"
            switch
            @change="onFilterChanged"
          >
            <strong>Show Records Without Mobile Number Only?</strong>
          </b-form-checkbox>
          <br>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            class="mr-2"
            @click="onShowAddForm"
          >
            Add Raw Data
          </b-button>
          <b-button
            v-if="useExcelUploader"
            type="button"
            variant="secondary"
            @click="onShowImportExcelForm"
          >
            Import from Excel
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(address)="data">
          <div class="text-nowrap">
            {{ data.item.home_address ? data.item.home_address : `${properCase(data.item.street_name) || ''}${data.item.street_name ? ',' : ''}${properCase(data.item.barangay?.barangay_name) || ''}${data.item.barangay ? ',' : ''} ${properCase(data.item.municipality?.municipality_name)}${data.item.municipality ? ',' : ''} ${properCase(data.item.province?.province_name)}` }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-raw-data-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="first_name"><strong>First Name</strong></label>
                  <b-input
                    id="first_name"
                    v-model="rawData.first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter first name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="last_name"><strong>Last Name</strong></label>
                  <b-input
                    id="last_name"
                    v-model="rawData.last_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter last name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="mobile number"
                vid="mobile_number"
                :rules="rulesMobileNumber"
              >
                <b-form-group>
                  <label for="mobile_number"><strong>Mobile Number</strong></label>
                  <b-input
                    id="mobile_number"
                    v-model="rawData.mobile_number"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter mobile number"
                    autocomplete="off"
                    maxlength="11"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="source"
                vid="source"
                rules="required"
              >
                <b-form-group>
                  <label for="sources"><strong>Source</strong></label>
                  <v-select
                    id="sources"
                    v-model="selected.source"
                    :options="list.sources"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.sources"
                    :loading="state.fetching.sources"
                    label="source_name"
                  >
                    <template #option="{ source_name }">
                      <strong>{{ source_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Sources
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="rawData.home_address !== null"
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="home address"
                vid="home_address"
                :rules="rulesHomeAddress"
              >
                <b-form-group>
                  <label for="home_address">Home Address</label>
                  <b-textarea
                    id="home_address"
                    v-model="rawData.home_address"
                    :state="errors.length > 0 ? false : null"
                    rows="6"
                    max-rows="6"
                    placeholder="street name, barangay, municipality, province"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="rawData.home_address === null"
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="street name"
                vid="street_name"
                rules="max:100"
              >
                <b-form-group>
                  <label for="street_name"><strong>Street Name (Home Address)</strong></label>
                  <b-input
                    id="street_name"
                    v-model="rawData.street_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter street name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="rawData.home_address === null"
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="province"
                vid="province"
                :rules="rulesProvince"
              >
                <b-form-group>
                  <label for="province"><strong>Province (Home Address)</strong></label>
                  <v-select
                    id="province"
                    v-model="selected.province"
                    :options="list.provinces"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.provinces"
                    :loading="state.fetching.provinces"
                    label="province_name"
                  >
                    <template #option="{ province_name }">
                      <strong>{{ province_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Provinces
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="rawData.home_address === null"
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="municipality"
                vid="municipality"
                :rules="rulesMunicipality"
              >
                <b-form-group>
                  <label for="municipality"><strong>Municipality (Home Address)</strong></label>
                  <v-select
                    id="municipality"
                    v-model="selected.municipality"
                    :options="list.municipalities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.municipalities"
                    :loading="state.fetching.municipalities"
                    label="municipality_name"
                  >
                    <template #option="{ municipality_name }">
                      <strong>{{ municipality_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Municipalities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="rawData.home_address === null"
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="barangay"
                vid="barangay"
                :rules="rulesBarangay"
              >
                <b-form-group>
                  <label for="barangay"><strong>Barangay (Home Address)</strong></label>
                  <v-select
                    id="barangay"
                    v-model="selected.barangay"
                    :options="list.barangays"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.barangays"
                    :loading="state.fetching.barangays"
                    label="barangay_name"
                  >
                    <template #option="{ barangay_name }">
                      <strong>{{ barangay_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Barangays
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="remarks"><strong>Remarks</strong></label>
                  <b-input
                    id="remarks"
                    v-model="rawData.remarks"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter remarks"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-import-from-excel"
      title="Import from Excel"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmUploadData"
    >
      <ValidationObserver ref="form2">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <!-- <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="province"
                vid="province"
                rules="required"
              >
                <b-form-group>
                  <label for="province"><strong>Province (Home Address)</strong></label>
                  <v-select
                    id="province"
                    v-model="selected2.province"
                    :options="list.provinces"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.provinces"
                    :loading="state.fetching.provinces"
                    label="province_name"
                  >
                    <template #option="{ province_name }">
                      <strong>{{ province_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Provinces
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="municipality"
                vid="municipality"
                rules=""
              >
                <b-form-group>
                  <label for="municipality"><strong>Municipality (Home Address)</strong></label>
                  <v-select
                    id="municipality"
                    v-model="selected2.municipality"
                    :options="list.municipalities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.municipalities"
                    :loading="state.fetching.municipalities"
                    label="municipality_name"
                  >
                    <template #option="{ municipality_name }">
                      <strong>{{ municipality_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Municipalities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="barangay"
                vid="barangay"
                rules=""
              >
                <b-form-group>
                  <label for="barangay"><strong>Barangay (Home Address)</strong></label>
                  <v-select
                    id="barangay"
                    v-model="selected2.barangay"
                    :options="list.barangays"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.barangays"
                    :loading="state.fetching.barangays"
                    label="barangay_name"
                  >
                    <template #option="{ barangay_name }">
                      <strong>{{ barangay_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Barangays
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="source"
                vid="source"
                rules="required"
              >
                <b-form-group>
                  <label for="sources"><strong>Source</strong></label>
                  <v-select
                    id="sources"
                    v-model="selected2.source"
                    :options="list.sources"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.uploading"
                    :loading="state.fetching.sources"
                    label="source_name"
                  >
                    <template #option="{ source_name }">
                      <strong>{{ source_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Sources
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <div
              class="col-12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-group>
                  <label for="file"><strong>Attach File:</strong></label>
                  <b-form-file
                    v-model="excelData.file"
                    :class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    accept=".xlsx"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || excelData.file === null || list.invalidRawData.length>0 || state.uploading)"
          @click="ok()"
        >
          Upload
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-invalid-excel-data"
      title="Invalid Data"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-table
        ref="tableInvalidRawData"
        hover
        responsive
        :per-page="tableInvalidRawData.perPage"
        :current-page="tableInvalidRawData.currentPage"
        :items="list.invalidRawData"
        :fields="tableInvalidRawData.fields"
        :sort-by.sync="tableInvalidRawData.sortBy"
        :sort-desc.sync="tableInvalidRawData.sortDesc"
        :sort-direction="tableInvalidRawData.sortDirection"
        :filter="tableInvalidRawData.filter"
        :filter-included-fields="tableInvalidRawData.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ (data.index + 1) }}
        </template>

        <template #cell(line_error)="data">
          <div class="text-nowrap text-danger">
            {{ data.item.line_error }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableInvalidRawData.perPage"
              :options="tableInvalidRawData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableInvalidRawData.currentPage"
            :total-rows="tableInvalidRawData.totalRows"
            :per-page="tableInvalidRawData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { AxiosError, UserRawDataService, UserImportDataService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import readXlsxFile from 'read-excel-file'

export default {
  name: 'UserRawData',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Raw Data'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          provinces: false,
          municipalities: false,
          barangays: false,
          sources: false
        }
      },
      filter: {
        without_mobile_number: 0,
        status: 'All'
      },
      list: {
        sources: [],
        provinces: [],
        municipalities: [],
        barangays: [],
        invalidRawData: []
      },
      options: {
        filter_statuses: [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Invalid', value: 'Invalid' }
        ]
      },
      selected: {
        current: null,
        source: null,
        province: null,
        municipality: null,
        barangay: null
      },
      selected2: {
        source: null,
        province: null,
        municipality: null,
        barangay: null
      },
      rawData: {
        id: 0,
        mobile_number: '',
        first_name: '',
        last_name: '',
        source: null,
        home_address: null,
        province: null,
        municipality: null,
        barangay: null,
        remarks: ''
      },
      excelData: {
        source: null,
        file: null,
        list: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'mobile_number' },
          { key: 'status' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'address', label: 'home address' },
          { key: 'call_attempt', label: 'unsuccessful call' },
          { key: 'status' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableInvalidRawData: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'mobile_number' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'home_address' },
          { key: 'remarks' },
          { key: 'line_number' },
          { key: 'line_error' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    useExcelUploader () {
      return ['fso'].includes(this.user?.user_scope)
    },

    modalTitle () {
      return this.state.editing ? 'Edit Raw Data' : 'Add Raw Data'
    },

    rulesMobileNumber () {
      return `unique:${this.rawData.id}|min:11|max:11`
    },

    rulesHomeAddress () {
      if (this.state.editing === true) {
        if (this.rawData.province === null) {
          return 'required|max:255'
        }
      }
      return ''
    },

    rulesProvince () {
      if (this.state.editing === true) {
        if (this.rawData.home_address === null) {
          return 'required'
        }
      }
      return 'required'
    },

    rulesMunicipality () {
      if (this.state.editing === true) {
        if (this.rawData.home_address === null) {
          return 'required'
        }
      }
      return 'required'
    },

    rulesBarangay () {
      if (this.state.editing === true) {
        if (this.rawData.home_address === null) {
          return 'required'
        }
      }
      return 'required'
    }
  },

  watch: {
    'selected.source' (value) {
      this.rawData.source = value?.id || null
    },

    'selected.province' (value) {
      this.rawData.province = value?.id || null
      this.getMunicipalities(this.rawData.province)
    },

    'selected.municipality' (value) {
      this.rawData.municipality = value?.id || null
      this.getBarangays(this.rawData.municipality)
    },

    'selected.barangay' (value) {
      this.rawData.barangay = value?.id || null
    },

    'selected2.source' (value) {
      this.excelData.source = value?.id || null
    },

    // 'selected2.province' (value) {
    //   this.excelData.province = value?.id || null
    //   this.getMunicipalities(this.excelData.province)
    // },

    // 'selected2.municipality' (value) {
    //   this.excelData.municipality = value?.id || null
    //   this.getBarangays(this.excelData.municipality)
    // },

    // 'selected2.barangay' (value) {
    //   this.excelData.barangay = value?.id || null
    // },

    'excelData.file' (value) {
      if (value) {
        this.onParseExcelFile(value)
      }
    },

    'list.invalidRawData' (value) {
      this.tableInvalidRawData.totalRows = value.length
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getProvinces()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserRawDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_status: this.filter.status,
            without_mobile_number: this.filter.without_mobile_number
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getSources () {
      this.state.fetching.sources = true
      await SharedListService.getSources().then(({ data }) => {
        this.list.sources = data
        this.state.fetching.sources = false
      })
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.rawData.id = 0
      this.rawData.active = 1
      this.rawData.first_name = ''
      this.rawData.last_name = ''
      this.rawData.mobile_number = ''
      this.rawData.source = null
      this.rawData.home_address = null
      this.rawData.street_name = ''
      this.rawData.province = null
      this.rawData.municipality = null
      this.rawData.barangay = null
      this.rawData.remarks = ''
      this.selected.source = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.list.invalidRawData = []
      this.$bvModal.show('modal-raw-data-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.rawData.id = current.id
      this.rawData.active = current.active
      this.rawData.first_name = current.first_name
      this.rawData.last_name = current.last_name
      this.rawData.mobile_number = current.mobile_number
      this.rawData.source = current.source_id
      this.rawData.home_address = current.home_address
      this.rawData.street_name = current.street_name
      this.rawData.remarks = current.remarks

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      if (current.source) {
        this.selected.source = {
          id: current.source.id,
          source_name: current.source.source_name
        }
      }

      this.$bvModal.show('modal-raw-data-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to submit raw data?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserRawDataService.post(this.rawData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-raw-data-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserRawDataService.put(this.rawData).then(({ data }) => {
          this.$bvModal.hide('modal-raw-data-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.raw_data.id })
            row.active = data.raw_data.active
            row.first_name = data.raw_data.first_name
            row.last_name = data.raw_data.last_name
            row.mobile_number = data.raw_data.mobile_number
            row.source = data.raw_data.source
            row.home_address = data.raw_data.home_address
            row.street_name = data.raw_data.street_name
            row.province = data.raw_data.province
            row.municipality = data.raw_data.municipality
            row.barangay = data.raw_data.barangay
            row.remarks = data.raw_data.remarks
            row.updated_at = data.raw_data.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onShowImportExcelForm () {
      this.selected2.source = null
      this.selected2.province = null
      this.selected2.municipality = null
      this.selected2.barangay = null
      this.excelData.file = null
      this.list.invalidRawData = []
      this.$bvModal.show('modal-import-from-excel')
    },

    async onParseExcelFile (file) {
      await readXlsxFile(file).then(excelRow => {
        this.rawData.data = []

        if (excelRow.length <= 1) {
          return 'No entries found'
        }

        if (excelRow[0][0] !== 'mobile_number' || excelRow[0][1] !== 'first_name' || excelRow[0][2] !== 'last_name' || excelRow[0][3] !== 'home_address' || excelRow[0][4] !== 'remarks') {
          return 'Column Titles must be (mobile_number, first_name, last_name, home_address, remarks)'
        }

        if (excelRow.length >= 501) {
          return 'Entries must not exceed beyond 500'
        }

        // reset list
        this.list.invalidRawData = []
        this.excelData.list = []

        for (let i = 1; i <= excelRow.length - 1; i++) {
          let isOk = true
          let error = ''

          if (excelRow[i][0] === null || excelRow[i][1] === null || excelRow[i][2] === null || excelRow[i][3] === null || excelRow[i][4] === null) {
            isOk = false
            error = 'row value cannot be empty.'
          }

          if (isOk) {
            if (String(excelRow[i][0]).length !== 11) {
              isOk = false
              error = 'mobile_number should be 11 characters.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 50) {
              isOk = false
              error = 'first_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 50) {
              isOk = false
              error = 'last_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][3]).length > 255) {
              isOk = false
              error = 'home_address should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][4]).length > 200) {
              isOk = false
              error = 'remarks should contain maximum of 200 characters only.'
            }
          }

          if (isOk) {
            this.excelData.list.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              home_address: excelRow[i][3],
              remarks: excelRow[i][4],
              line_number: i + 1,
              line_error: ''
            })
          } else {
            this.list.invalidRawData.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              home_address: excelRow[i][3],
              remarks: excelRow[i][4],
              line_number: i + 1,
              line_error: error
            })
          }
        }

        if (this.list.invalidRawData.length > 0) {
          this.$bvModal.show('modal-invalid-excel-data')
        }

        return null
      }).then(error => {
        if (error) {
          this.$refs.form2.setErrors({
            file: [error]
          })
        }
      }).catch(() => {
        this.$refs.form2.setErrors({
          file: ['There was a problem reading your uploaded excel file.']
        })
      })
    },

    async onConfirmUploadData (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form2.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to upload raw data?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onUploadFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onUploadFormPostSubmit () {
      return new Promise(resolve => {
        this.state.uploading = true
        UserImportDataService.post(this.excelData).then(({ data }) => {
          this.state.uploading = false
          this.$bvModal.hide('modal-import-from-excel')
          this.list.invalidRawData = data.invalid_raw_data

          if (data.total_uploaded === data.total_valid) {
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }

          if (data.total_uploaded === data.total_invalid) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! All the raw data you uploaded in invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }

          if (parseInt(data.total_valid) > 0 && parseInt(data.total_invalid) > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! Some of the raw data you uploaded is invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }
        }).catch(error => {
          this.state.uploading = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form2.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
